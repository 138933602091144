var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "actions flex jc-end mb-4" },
        [
          _vm.selectedRowKeys.length && _vm.parent.isTongChou
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:stage:button:delete",
                      expression: "'production:stage:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          this.userEdit
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:chapter:button:edit",
                      expression: "'production:chapter:button:edit'",
                    },
                  ],
                  staticClass: "mr-3",
                  on: { click: _vm.listCancel },
                },
                [_vm._v("取消")]
              )
            : _vm._e(),
          _vm.parent.isTongChou && _vm.selectedRowKeys.length == 1
            ? _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "primary" },
                  on: { click: _vm.openChapter },
                },
                [_vm._v("生成任务")]
              )
            : _vm._e(),
          !_vm.isAddProduction && _vm.parent.isTongChou
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:chapter:button:edit",
                      expression: "'production:chapter:button:edit'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: {
                    icon: _vm.userEdit ? "save" : "edit",
                    type: _vm.userEdit ? "" : "primary",
                  },
                  on: { click: _vm.startEditUser },
                },
                [_vm._v(_vm._s(_vm.userEdit ? "保存" : "编辑"))]
              )
            : _vm._e(),
          _vm.parent.isTongChou
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:stage:button:add",
                      expression: "'production:stage:button:add'",
                    },
                  ],
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.openAddForm },
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("a-table", {
        directives: [
          {
            name: "has",
            rawName: "v-has",
            value: "production:stage:page:view",
            expression: "'production:stage:page:view'",
          },
        ],
        ref: "table",
        staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
        attrs: {
          size: "middle",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          customRow:
            _vm.checkPermission("production:stage:button:viewModal") &&
            !_vm.userEdit &&
            !_vm.isAddProduction
              ? _vm.clickRow
              : () => {},
          scroll: { x: 1000, y: 500 },
          pagination: _vm.ipagination,
          loading: _vm.loading,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 90,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "reversible",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("bool")(record.reversible)) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "userName",
            fn: function (text, record) {
              return [
                _vm.userEdit || _vm.isAddProduction
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "100px" },
                            attrs: {
                              "default-value": record.userName,
                              "show-search": "",
                              "option-filter-prop": "label",
                            },
                            on: {
                              search: (value) => _vm.userSearch(value.record),
                              change: (v, o) =>
                                _vm.handleUserChange(v, o, record),
                            },
                            model: {
                              value: record.userId,
                              callback: function ($$v) {
                                _vm.$set(record, "userId", $$v)
                              },
                              expression: "record.userId",
                            },
                          },
                          _vm._l(
                            record.copyStageAdminUsers.filter(
                              (item) => !item.enabled
                            ),
                            function (i) {
                              return _c(
                                "a-select-option",
                                {
                                  key: i.userId,
                                  attrs: { value: i.userId, label: i.userName },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      i.avatar
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "30px",
                                              height: "30px",
                                              "border-radius": "50%",
                                              "margin-right": "5px",
                                              "margin-left": "5px",
                                            },
                                            attrs: { src: i.avatar, alt: "" },
                                          })
                                        : _vm._e(),
                                      _c("span", [_vm._v(_vm._s(i.userName))]),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", [
                      _c("div", [
                        record.user
                          ? _c("img", {
                              staticStyle: {
                                width: "30px",
                                height: "30px",
                                "border-radius": "50%",
                                "margin-right": "10px",
                              },
                              attrs: { src: record.user.avatar, alt: "" },
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(record ? record.userName : "") +
                            "\n        "
                        ),
                      ]),
                    ]),
              ]
            },
          },
          {
            key: "evaluate",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("bool")(record.ruleId > 0)) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "rate",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm._f("bool")(record.rate)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "ruleId",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(record.ruleId > 0 ? "规则" + record.ruleId : "无") +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "costTime",
            fn: function (text, record) {
              return [
                _vm.userEdit || _vm.isAddProduction
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("a-input", {
                          on: {
                            blur: (e) =>
                              _vm.handleTimeChange(
                                e.target.value,
                                null,
                                record
                              ),
                          },
                          model: {
                            value: record.predefinedTime,
                            callback: function ($$v) {
                              _vm.$set(record, "predefinedTime", $$v)
                            },
                            expression: "record.predefinedTime",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            record.predefinedTime != null ||
                              record.predefinedTime != undefined
                              ? record.predefinedTime + " 天"
                              : ""
                          ) +
                          "\n      "
                      ),
                    ]),
              ]
            },
          },
          {
            key: "ruleList",
            fn: function (text, record) {
              return _vm._l(record.ruleList, function (one) {
                return _c(
                  "a-tag",
                  {
                    key: one.id,
                    attrs: { color: one.hasUpdated ? "green" : "" },
                  },
                  [_vm._v(_vm._s(one.name))]
                )
              })
            },
          },
          {
            key: "rejectList",
            fn: function (text, record) {
              return _vm._l(record.rejectList, function (one) {
                return _c("a-tag", { key: one.stageId }, [
                  _vm._v("\n        " + _vm._s(one.stageName) + "\n      "),
                ])
              })
            },
          },
          {
            key: "kpiList",
            fn: function (text, record) {
              return _vm._l(record.kpiList, function (one) {
                return _c("a-tag", { key: one.stageId }, [
                  _vm._v("\n        " + _vm._s(one.stageName) + "\n      "),
                ])
              })
            },
          },
          {
            key: "applyTranslation",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("a-switch", {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "production:chapter:button:edit",
                        expression: "'production:chapter:button:edit'",
                      },
                    ],
                    on: { change: _vm.saveTableData },
                    model: {
                      value: record.applyTranslation,
                      callback: function ($$v) {
                        _vm.$set(record, "applyTranslation", $$v)
                      },
                      expression: "record.applyTranslation",
                    },
                  }),
                ],
                1
              )
            },
          },
          {
            key: "applyReview",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("a-switch", {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "production:chapter:button:edit",
                        expression: "'production:chapter:button:edit'",
                      },
                    ],
                    on: { change: _vm.saveTableData },
                    model: {
                      value: record.applyReview,
                      callback: function ($$v) {
                        _vm.$set(record, "applyReview", $$v)
                      },
                      expression: "record.applyReview",
                    },
                  }),
                ],
                1
              )
            },
          },
          {
            key: "status",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("a-switch", {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "production:chapter:button:edit",
                        expression: "'production:chapter:button:edit'",
                      },
                    ],
                    on: { change: _vm.saveTableData },
                    model: {
                      value: record.status,
                      callback: function ($$v) {
                        _vm.$set(record, "status", $$v)
                      },
                      expression: "record.status",
                    },
                  }),
                ],
                1
              )
            },
          },
        ]),
      }),
      !_vm.isAdd
        ? _c("stage-modal", {
            attrs: {
              productionId: _vm.parent.id,
              parent: _vm.parent,
              adminUsers: _vm.adminUsers,
              open: _vm.drawerShow,
              "cur-row": _vm.curRow,
              isForm: _vm.isNewForm,
              allRuleList: _vm.ruleList,
            },
            on: { closed: _vm.drawerClosed },
          })
        : _c("stage-table-modal", {
            attrs: {
              productionId: _vm.parent.id,
              parent: _vm.parent,
              adminUsers: _vm.adminUsers,
              open: _vm.drawerShow,
              "cur-row": _vm.curRow,
              isForm: _vm.isNewForm,
              allRuleList: _vm.ruleList,
            },
            on: { closed: _vm.drawerClosed },
          }),
      _c(
        "a-modal",
        {
          attrs: { maskClosable: false },
          on: { cancel: _vm.cancel, ok: _vm.handleOk },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "div",
              [
                _c("a-icon", {
                  style: `color:#ffa500 ;margin-right: 10px;`,
                  attrs: { type: "exclamation-circle", theme: "filled" },
                }),
                _vm._v("请选择换人方式\n      "),
              ],
              1
            ),
          ]),
          _c("a-radio-group", {
            staticClass: "tab-stage-rario-group",
            staticStyle: { width: "60%", "line-height": "2" },
            attrs: { options: _vm.plainOptions },
            model: {
              value: _vm.radioValue,
              callback: function ($$v) {
                _vm.radioValue = $$v
              },
              expression: "radioValue",
            },
          }),
          _vm.radioValue == "1"
            ? _c("div", { staticStyle: { "padding-top": "15px" } }, [
                _c("span", [
                  _vm._v("请确认要换人的章节序号（不换人的可删除）："),
                ]),
                _vm.chapterList.length
                  ? _c(
                      "div",
                      { staticStyle: { "padding-top": "15px" } },
                      _vm._l(_vm.chapterList, function (tag) {
                        return _c(
                          "a-tag",
                          {
                            key: tag.id,
                            staticClass: "tab-stage-tag",
                            style:
                              "color:" +
                              _vm.$store.state.app.color +
                              "!important;padding-left:15px;",
                            attrs: { color: _vm.color, closable: "" },
                            on: { close: () => _vm.handleClose(tag) },
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(tag.value) + "\n        "
                            ),
                          ]
                        )
                      }),
                      1
                    )
                  : _c("div", { staticStyle: { "padding-top": "6px" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#bfbfbf",
                          },
                        },
                        [_vm._v("暂无可更改章节，人员配置将从新建章节开始生效")]
                      ),
                    ]),
              ])
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.cancel } }, [
                _vm._v("\n        取消\n      "),
              ]),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleOk },
                },
                [_vm._v("\n        确定\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.chapterVisible },
          on: { ok: _vm.handleVisibleOk, cancel: _vm.handleVisibleCancel },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "div",
              [
                _c("a-icon", {
                  style: `color:#ffa500 ;margin-right: 10px;`,
                  attrs: { type: "exclamation-circle", theme: "filled" },
                }),
                _vm._v("请选择生效章节\n      "),
              ],
              1
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.radioChapterChange },
                  model: {
                    value: _vm.radioChapterValue,
                    callback: function ($$v) {
                      _vm.radioChapterValue = $$v
                    },
                    expression: "radioChapterValue",
                  },
                },
                [
                  _c(
                    "a-radio",
                    {
                      style: {
                        display: "block",
                        height: "30px",
                        lineHeight: "30px",
                      },
                      attrs: { value: 1 },
                    },
                    [_vm._v("\n          从新建章节开始生效\n        ")]
                  ),
                  _c(
                    "a-radio",
                    {
                      style: {
                        display: "block",
                        height: "30px",
                        lineHeight: "30px",
                      },
                      attrs: { value: 2 },
                    },
                    [_vm._v("\n          从现有章节开始生效\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.radioChapterValue == 2
            ? _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("span", [
                  _vm._v("请确认要生成任务的章节序号（不生成的可删除）："),
                ]),
                _c(
                  "div",
                  { staticStyle: { "padding-top": "15px" } },
                  _vm._l(_vm.selectChapterList, function (chapter) {
                    return _c(
                      "a-tag",
                      {
                        key: chapter.chapterId,
                        staticClass: "tab-stage-tag",
                        style:
                          "color:" +
                          _vm.$store.state.app.color +
                          "!important;padding-left:15px;",
                        attrs: { closable: "", color: _vm.color },
                        on: { close: () => _vm.handleCloseTask(chapter) },
                      },
                      [_vm._v(_vm._s(chapter.sequenceNum))]
                    )
                  }),
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.chapterBtnLoading },
                  on: { click: _vm.handleVisibleCancel },
                },
                [_vm._v("\n        取消\n      ")]
              ),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { loading: _vm.chapterBtnLoading, type: "primary" },
                  on: { click: _vm.handleVisibleOk },
                },
                [_vm._v("\n        确定\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }