<template>
  <div>
    <div class="actions flex jc-end mb-4">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length && parent.isTongChou"
        icon="delete"
        v-has="'production:stage:button:delete'"
        >删除</a-button
      >
      <a-button v-if="this.userEdit" class="mr-3" @click="listCancel" v-has="'production:chapter:button:edit'"
        >取消</a-button
      >
      <a-button v-if="parent.isTongChou && selectedRowKeys.length == 1" type="primary" @click="openChapter" class="mr-3"
        >生成任务</a-button
      >
      <a-button
        v-if="!isAddProduction && parent.isTongChou"
        class="mr-3"
        :icon="userEdit ? 'save' : 'edit'"
        :type="userEdit ? '' : 'primary'"
        @click="startEditUser"
        v-has="'production:chapter:button:edit'"
        >{{ userEdit ? '保存' : '编辑' }}</a-button
      >
      <a-button
        v-if="parent.isTongChou"
        type="primary"
        @click="openAddForm"
        icon="plus"
        v-has="'production:stage:button:add'"
        >添加</a-button
      >
    </div>
    <a-table
      style="border-top:1px solid rgb(232,232,232)"
      v-has="'production:stage:page:view'"
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="
        checkPermission('production:stage:button:viewModal') && !userEdit && !isAddProduction ? clickRow : () => {}
      "
      :scroll="{ x: 1000, y: 500 }"
      :pagination="ipagination"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 90 }"
      @change="handleTableChange"
    >
      <template slot="reversible" slot-scope="text, record">
        {{ record.reversible | bool }}
      </template>
      <template slot="userName" slot-scope="text, record">
        <!-- {{String(isAddProduction)}} -->
        <div @click.stop v-if="userEdit || isAddProduction">
          <a-select
            :default-value="record.userName"
            style="width: 100px"
            v-model="record.userId"
            show-search
            @search="value=>userSearch(value.record)"
            option-filter-prop="label"
            @change="(v, o) => handleUserChange(v, o, record)"
          >
            <a-select-option
              v-for="i in record.copyStageAdminUsers.filter(item => !item.enabled)"
              :value="i.userId"
              :key="i.userId"
              :label="i.userName"
            >
              <div style="display:flex">
                <img
                  v-if="i.avatar"
                  style="width:30px;height:30px;border-radius:50%;margin-right:5px;margin-left:5px;"
                  :src="i.avatar"
                  alt=""
                />
                <span>{{ i.userName }}</span>
              </div>
            </a-select-option>
          </a-select>
        </div>
        <div v-else>
          <div>
            <img
              v-if="record.user"
              style="width:30px;height:30px;border-radius:50%;margin-right:10px;"
              :src="record.user.avatar"
              alt=""
            />
            {{ record ? record.userName : '' }}
          </div>
        </div>
      </template>
      <template slot="evaluate" slot-scope="text, record">
        {{ (record.ruleId > 0) | bool }}
      </template>
      <template slot="rate" slot-scope="text, record">
        {{ record.rate | bool }}
      </template>
      <template slot="ruleId" slot-scope="text, record">
        {{ record.ruleId > 0 ? '规则' + record.ruleId : '无' }}
      </template>
      <template slot="costTime" slot-scope="text, record">
        <div @click.stop v-if="userEdit || isAddProduction">
          <a-input @blur="e => handleTimeChange(e.target.value, null, record)" v-model="record.predefinedTime" />
        </div>
        <div v-else>
          {{ record.predefinedTime != null || record.predefinedTime != undefined ? record.predefinedTime + ' 天' : '' }}
        </div>
      </template>
      <template slot="ruleList" slot-scope="text, record">
        <a-tag :color="one.hasUpdated ? 'green' : ''" v-for="one in record.ruleList" :key="one.id">{{
          one.name
        }}</a-tag>
      </template>
      <template slot="rejectList" slot-scope="text, record">
        <a-tag v-for="one in record.rejectList" :key="one.stageId">
          {{ one.stageName }}
        </a-tag>
      </template>
      <template slot="kpiList" slot-scope="text, record">
        <a-tag v-for="one in record.kpiList" :key="one.stageId">
          {{ one.stageName }}
        </a-tag>
      </template>
      <span @click.stop slot="applyTranslation" slot-scope="text, record">
        <!-- <span v-if="record.cardList">{{ record.canSync || record.canSync === null ? '允许' : '不允许' }}</span> -->
        <a-switch v-has="'production:chapter:button:edit'" v-model="record.applyTranslation" @change="saveTableData" />
      </span>
      <span @click.stop slot="applyReview" slot-scope="text, record">
        <!-- <span v-if="record.cardList">{{ record.canSync || record.canSync === null ? '允许' : '不允许' }}</span> -->
        <a-switch v-has="'production:chapter:button:edit'" v-model="record.applyReview" @change="saveTableData" />
      </span>
      <span @click.stop slot="status" slot-scope="text, record">
        <a-switch v-has="'production:chapter:button:edit'" v-model="record.status" @change="saveTableData" />
      </span>
    </a-table>
    <stage-modal
      v-if="!isAdd"
      :productionId="parent.id"
      :parent="parent"
      :adminUsers="adminUsers"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      :allRuleList="ruleList"
      @closed="drawerClosed"
    ></stage-modal>
    <stage-table-modal
      v-else
      :productionId="parent.id"
      :parent="parent"
      :adminUsers="adminUsers"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      :allRuleList="ruleList"
      @closed="drawerClosed"
    ></stage-table-modal>
    <a-modal v-model="visible" :maskClosable="false" @cancel="cancel" @ok="handleOk">
      <template slot="title">
        <div>
          <a-icon
            type="exclamation-circle"
            :style="`color:#ffa500 ;margin-right: 10px;`"
            theme="filled"
          />请选择换人方式
        </div>
      </template>
      <a-radio-group
        class="tab-stage-rario-group"
        style="width: 60%;line-height:2"
        :options="plainOptions"
        v-model="radioValue"
      />
      <div v-if="radioValue == '1'" style="padding-top:15px;">
        <span>请确认要换人的章节序号（不换人的可删除）：</span>
        <div style="padding-top:15px;" v-if="chapterList.length">
          <a-tag
            class="tab-stage-tag"
            :color="color"
            :style="'color:' + $store.state.app.color + '!important;padding-left:15px;'"
            v-for="tag in chapterList"
            closable
            :key="tag.id"
            @close="() => handleClose(tag)"
          >
            {{ tag.value }}
          </a-tag>
        </div>
        <div v-else style="padding-top:6px">
          <span style="font-size:12px;color: #bfbfbf;">暂无可更改章节，人员配置将从新建章节开始生效</span>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="cancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleOk">
          确定
        </a-button>
      </template>
    </a-modal>
    <a-modal :visible="chapterVisible" @ok="handleVisibleOk" @cancel="handleVisibleCancel">
      <template slot="title">
        <div>
          <a-icon
            type="exclamation-circle"
            :style="`color:#ffa500 ;margin-right: 10px;`"
            theme="filled"
          />请选择生效章节
        </div>
      </template>
      <div>
        <a-radio-group v-model="radioChapterValue" @change="radioChapterChange">
          <a-radio
            :style="{
              display: 'block',
              height: '30px',
              lineHeight: '30px'
            }"
            :value="1"
          >
            从新建章节开始生效
          </a-radio>
          <a-radio
            :style="{
              display: 'block',
              height: '30px',
              lineHeight: '30px'
            }"
            :value="2"
          >
            从现有章节开始生效
          </a-radio>
        </a-radio-group>
      </div>
      <div v-if="radioChapterValue == 2" style="margin-top:10px">
        <span>请确认要生成任务的章节序号（不生成的可删除）：</span>
        <div style="padding-top:15px;">
          <a-tag
            v-for="chapter in selectChapterList"
            :key="chapter.chapterId"
            closable
            :color="color"
            class="tab-stage-tag"
            @close="() => handleCloseTask(chapter)"
            :style="'color:' + $store.state.app.color + '!important;padding-left:15px;'"
            >{{ chapter.sequenceNum }}</a-tag
          >
        </div>
      </div>
      <template slot="footer">
        <a-button :loading="chapterBtnLoading" @click="handleVisibleCancel">
          取消
        </a-button>
        <a-button :loading="chapterBtnLoading" key="submit" type="primary" @click="handleVisibleOk">
          确定
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { postAction, getAction, putAction, deleteAction } from '@/api/manage'
import { checkPermission } from '@/utils/hasPermission'
import StageModal from './stage-modal'
import StageTableModal from './stage-table-modal'
import TagDragSelect from '@/components/TagDragSelect'
export default {
  mixins: [JeecgListMixin],
  components: {
    StageTableModal,
    StageModal,
    TagDragSelect
  },
  props: {
    parent: {
      type: Object,
      default: () => {}
    },
    platform: {
      type: Object,
      default: () => ({})
    },
    tab: {
      type: String,
      default: '1'
    },
    isAddProduction: {
      type: Boolean,
      default: false
    },
    adminUsers: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    tab(n) {
      // this.loadData();
    }
  },
  data() {
    return {
      color: '',
      radioValue: '2',
      plainOptions: [
        { label: '从新建章节开始换人', value: '2' },
        { label: '从现有任务开始换人', value: '1' }
      ],
      visible: false,
      chapterVisible: false,
      chapterBtnLoading: false,
      radioChapterValue: 1,
      selectChapterList: [],
      applyTranslation: false,
      applyReview: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 100
        },
        {
          title: '阶段名称',
          dataIndex: 'stageName',
          width: 100
        },
        {
          title: '人员',
          width: 130,
          dataIndex: 'userName',
          scopedSlots: { customRender: 'userName' }
        },
        /*         {
          title: '是否可逆',
          width: 100,
          dataIndex: 'reversible',
          scopedSlots: { customRender: 'reversible' },
        }, */
        {
          title: '可驳回阶段',
          scopedSlots: { customRender: 'rejectList' },
          dataIndex: 'rejectList',
          width: 120
        },
        {
          title: '可考核阶段',
          scopedSlots: { customRender: 'kpiList' },
          dataIndex: 'kpiList',
          width: 120
        },
        // {
        //   title: '是否需考核',
        //   dataIndex: 'evaluate',
        //   scopedSlots: { customRender: 'evaluate' },
        // },
        // {
        //   title: '是否需评级',
        //   scopedSlots: { customRender: 'rate' },
        //   dataIndex: 'rate',
        // },
        // {
        //   title: '价格',
        //   scopedSlots: { customRender: 'ruleId' },
        //   dataIndex: 'ruleId',
        // },
        {
          title: '预计耗时',
          width: 100,
          scopedSlots: { customRender: 'costTime' },
          dataIndex: 'predefinedTime'
        },
        {
          title: '状态',
          width: 150,
          scopedSlots: { customRender: 'status' },
          dataIndex: 'status'
        },
        {
          title: () => {
            return this.checkPermission('production:chapter:button:edit') && this.isShow ? (
              <div style="display:flex;align-items:center">
                <span>翻译系统</span>
                <a-switch
                  style="margin-left:10px"
                  ref="applyTranslationRef"
                  v-model={this.applyTranslation}
                  onChange={this.applyTranslationChange}
                />
              </div>
            ) : (
              '翻译系统'
            )
          },
          width: 150,
          scopedSlots: { customRender: 'applyTranslation' },
          dataIndex: 'applyTranslation'
        },
        {
          title: () => {
            return this.checkPermission('production:chapter:button:edit') && this.isShow ? (
              <div style="display:flex;align-items:center">
                <span>查稿系统</span>
                <a-switch style="margin-left:10px" v-model={this.applyReview} onChange={this.applyReviewChange} />
              </div>
            ) : (
              '翻译系统'
            )
          },
          width: 150,
          scopedSlots: { customRender: 'applyReview' },
          dataIndex: 'applyReview'
        },
        {
          title: '规则',
          width: 400,
          dataIndex: 'ruleList',
          scopedSlots: { customRender: 'ruleList' }
        }
      ],
      ruleList: [],
      isShow: false,
      tableUserLoading: false,
      userEdit: false,
      chapterList: [],
      openRecord: {}
    }
  },
  computed: {},
  methods: {
    userSearch(value,record){
      if(value){
        record.copyStageAdminUsers = record.stageAdminUsers.filter(item => item.userName.toLowerCase().indexOf(value.toLowerCase()) > -1)
      }
    },
    batchDel: function() {
      console.log('JeecgListMixin batchDel')
      if (!this.url.deleteBatch) {
        this.$message.error('请设置url.deleteBatch属性!')
        return
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
        return
      } else {
        var ids = this.selectedRowKeys.join(',')
        var that = this

        let url = that.url.deleteBatch
        // 增加作品管理的产品id
        if (this.productionId) {
          url = `${url}/${this.productionId}`
        } else if (this.platformId) {
          url = `${url}/${this.platformId}`
        }

        this.$confirm({
          title: '确认删除',
          content: '删除阶段会将已有任务一起删除且无法恢复，是否确认?',
          onOk: function() {
            that.loading = true
            deleteAction(url, { ids: ids })
              .then(res => {
                if (res.success) {
                  that.$message.success(res.message || res.msg ? '删除成功' : '')
                  if (that.$refs.newTable) {
                    that.$refs.newTable.loadData()
                  } else {
                    that.loadData()
                  }
                  that.onClearSelected()
                  that.$emit('loadData')
                } else {
                  that.$message.warning(res.message || res.msg)
                }
              })
              .finally(() => {
                that.loading = false
              })
          }
        })
      }
    },
    async radioChapterChange(e) {
      if (e.target.value == 2) {
        const res = await getAction(
          '/production/addStageBeforeCheck?productionId=' +
            this.parent.id +
            '&sequenceNo=' +
            this.selectionRows[0].sequenceNo
        )
        if (res.code == 200) {
          if (res.data.length) {
            this.selectChapterList = res.data
          } else {
            this.$message.error('现有章节无法添加该阶段任务')
            this.radioChapterValue = 1
          }
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    async openChapter() {
      const res = await getAction(
        '/production/addStageBeforeCheck?productionId=' +
          this.parent.id +
          '&sequenceNo=' +
          this.selectionRows[0].sequenceNo
      )
      if (res.code == 200) {
        if (res.data.length) {
          this.chapterVisible = true
          this.radioChapterValue = 1
          this.selectChapterList = res.data
        } else {
          this.$message.error('现有章节无法添加该阶段任务')
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    async handleVisibleOk() {
      if (this.radioChapterValue == 2) {
        if (!this.selectionRows.length) return
        let params = {
          chapterIds: this.selectChapterList.map(item => item.chapterId),
          productionId: this.parent.id,
          stageId: this.selectionRows[0].id
        }
        this.chapterBtnLoading = true
        const res = await postAction('/production/add_stage_task', params)
        this.chapterBtnLoading = false
        if (res.code == 200) {
          this.$message.success('生成成功')
          this.chapterVisible = false
          this.selectionRows = []
          this.selectedRowKeys = []
        } else {
          this.$message.error(res.msg)
        }
      } else {
        this.chapterVisible = false
      }
    },
    async applyTranslationChange(e) {
      this.dataSource.forEach(item => {
        item.applyTranslation = e
      })
      let arr = JSON.parse(JSON.stringify(this.dataSource))
      arr.forEach(item => {
        if (item.applyTranslation) {
          item.applyTranslation = 1
        } else {
          item.applyTranslation = 0
        }
        if (item.applyReview) {
          item.applyReview = 1
        } else {
          item.applyReview = 0
        }
        if (item.status) {
          item.status = 0
        } else {
          item.status = 1
        }
      })
      const res = await postAction('/production/updateStages', arr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.dataSource.forEach(item => {
          if (item.applyTranslation) {
            item.applyTranslation = true
          } else {
            item.applyTranslation = false
          }
          if (item.applyReview) {
            item.applyReview = true
          } else {
            item.applyReview = false
          }
          if (item.status == '1') {
            item.status = true
          } else {
            item.status = false
          }
        })
        if (this.dataSource.every(item => item.applyTranslation)) {
          this.applyTranslation = true
        } else {
          this.applyTranslation = false
        }
        if (this.dataSource.every(item => item.applyReview)) {
          this.applyReview = true
        } else {
          this.applyReview = false
        }
      } else {
        this.$message.error('保存失败')
      }
    },
    async applyReviewChange(e) {
      this.dataSource.forEach(item => {
        item.applyReview = e
      })
      let arr = JSON.parse(JSON.stringify(this.dataSource))
      arr.forEach(item => {
        if (item.applyTranslation) {
          item.applyTranslation = 1
        } else {
          item.applyTranslation = 0
        }
        if (item.applyReview) {
          item.applyReview = 1
        } else {
          item.applyReview = 0
        }
        if (item.status) {
          item.status = 0
        } else {
          item.status = 1
        }
      })
      const res = await postAction('/production/updateStages', arr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.dataSource.forEach(item => {
          if (item.applyTranslation) {
            item.applyTranslation = true
          } else {
            item.applyTranslation = false
          }
          if (item.applyReview) {
            item.applyReview = true
          } else {
            item.applyReview = false
          }
          if (item.status == '1') {
            item.status = true
          } else {
            item.status = false
          }
        })
        if (this.dataSource.every(item => item.applyTranslation)) {
          this.applyTranslation = true
        } else {
          this.applyTranslation = false
        }
        if (this.dataSource.every(item => item.applyReview)) {
          this.applyReview = true
        } else {
          this.applyReview = false
        }
      } else {
        this.$message.error('保存失败')
      }
    },
    async saveTableData() {
      let arr = JSON.parse(JSON.stringify(this.dataSource))
      arr.forEach(item => {
        if (item.applyTranslation) {
          item.applyTranslation = 1
        } else {
          item.applyTranslation = 0
        }
        if (item.applyReview) {
          item.applyReview = 1
        } else {
          item.applyReview = 0
        }
        if (item.status) {
          item.status = 0
        } else {
          item.status = 1
        }
      })
      const res = await postAction('/production/updateStages', arr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.dataSource.forEach(item => {
          if (item.applyTranslation) {
            item.applyTranslation = true
          } else {
            item.applyTranslation = false
          }
          if (item.applyReview) {
            item.applyReview = true
          } else {
            item.applyReview = false
          }
          if (item.status == '1') {
            item.status = true
          } else {
            item.status = false
          }
        })
        if (this.dataSource.every(item => item.applyTranslation)) {
          this.applyTranslation = true
        } else {
          this.applyTranslation = false
        }
        if (this.dataSource.every(item => item.applyReview)) {
          this.applyReview = true
        } else {
          this.applyReview = false
        }
      } else {
        this.$message.error('保存失败')
      }
    },
    handleVisibleCancel() {
      this.chapterVisible = false
      this.selectionRows = []
      this.selectedRowKeys = []
    },
    listCancel() {
      this.userEdit = false
      this.loadData()
    },
    handleClose(removedTag) {
      const tags = this.chapterList.filter(tag => tag.id !== removedTag.id)
      this.chapterList = tags
    },
    handleCloseTask(removedTag) {
      const tags = this.selectChapterList.filter(tag => tag.chapterId !== removedTag.chapterId)
      this.selectChapterList = tags
    },
    async getChapterList(record, value) {
      const res = await getAction(
        `/tasks/noFinishChapters?productId=${this.$route.params.id}&stageId=${record.stageId}`
      )
      this.chapterList = res.data.map(item => {
        return { value: item.sequenceNo, id: item.chapterId }
      })
    },
    async cancel() {
      this.visible = false
      this.userEdit = false
      this.$nextTick(() => {
        this.userEdit = true
      })
    },
    async handleOk() {
      if (this.radioValue == '1') {
        const data = {
          stageId: this.openRecord.stageId,
          productId: this.$route.params.id,
          chapterIds: this.chapterList.map(item => {
            return item.id
          }),
          userId: this.openRecord.userId
        }
        const res = await postAction('/tasks/updateMember', data)
        if (res.code == 200) {
          let openRecord = JSON.parse(JSON.stringify(this.openRecord))
          if (openRecord.applyTranslation) {
            openRecord.applyTranslation = 1
          } else {
            openRecord.applyTranslation = 0
          }
          if (openRecord.applyReview) {
            openRecord.applyReview = 1
          } else {
            openRecord.applyReview = 0
          }
          if (openRecord.status) {
            openRecord.status = 0
          } else {
            openRecord.status = 1
          }
          await putAction('/production/edit_stage', openRecord)
          this.$message.success('保存成功')
          this.visible = false
          this.visibleTag = true
          this.userEdit = false
          this.loadData()
        } else {
          this.$message.error(res.msg || res.message)
        }
      } else {
        let openRecord = JSON.parse(JSON.stringify(this.openRecord))
        if (openRecord.applyTranslation) {
          openRecord.applyTranslation = 1
        } else {
          openRecord.applyTranslation = 0
        }
        if (openRecord.applyReview) {
          openRecord.applyReview = 1
        } else {
          openRecord.applyReview = 0
        }
        if (openRecord.status) {
          openRecord.status = 0
        } else {
          openRecord.status = 1
        }
        await putAction('/production/edit_stage', openRecord)
        this.loadData()
        this.visible = false
        this.visibleTag = true
        this.userEdit = false
      }
    },
    async startEditUser() {
      if (this.userEdit) {
        let arr = JSON.parse(JSON.stringify(this.dataSource))
        arr.forEach(item => {
          if (item.applyTranslation) {
            item.applyTranslation = 1
          } else {
            item.applyTranslation = 0
          }
          if (item.applyReview) {
            item.applyReview = 1
          } else {
            item.applyReview = 0
          }
          if (item.status) {
            item.status = 0
          } else {
            item.status = 1
          }
        })
        const res = await postAction('/production/updateStages', arr)
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.loadData()
          this.userEdit = !this.userEdit
        } else {
          this.$message.error('保存失败')
        }
      } else {
        this.userEdit = !this.userEdit
      }
    },
    checkPermission(suc) {
      return checkPermission(suc)
    },
    async getRuleList() {
      try {
        const res = await getAction('/rule/list', {
          domain: 'PLATFORM',
          departId: this.parent.departmentId,
          platformId: this.parent.platformId
        })
        if (res.code == 200) {
          this.ruleList = res.data
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    /*     usersFilterByPositionId(positionId) {
      return this.adminUsers.filter((users) => users?.positions?.some(({ positionId: id }) => id == positionId))
    }, */
    handleTimeBlur(record) {
      let copyItem = JSON.parse(JSON.stringify(record))
      if (copyItem.applyTranslation) {
        copyItem.applyTranslation = 1
      } else {
        copyItem.applyTranslation = 0
      }
      if (copyItem.applyReview) {
        copyItem.applyReview = 1
      } else {
        copyItem.applyReview = 0
      }
      if (copyItem.status) {
        copyItem.status = 0
      } else {
        copyItem.status = 1
      }
      putAction('/production/edit_stage', copyItem).then(({ success, data }) => {
        console.log({ success, data })
      })
    },
    async handleUserChange(value, option, record) {
      if (!this.isAddProduction) {
        const res = await getAction(
          `/tasks/noFinishChapters?productId=${this.$route.params.id}&stageId=${record.stageId}`
        )
        this.chapterList = res.data.map(item => {
          return { value: item.sequenceNo, id: item.chapterId }
        })
      }
      if (this.isAddProduction || !record.userId || !this.chapterList.length) {
        record.userId = value
        this.dataSource.forEach((item, index) => {
          if (
            JSON.stringify(item.stageAdminUsers) == JSON.stringify(record.stageAdminUsers) &&
            index > this.dataSource.findIndex(i => i.id == record.id)
          ) {
            item.userId = value
            item.userName = item.stageAdminUsers.filter(i => i.userId == item.userId)[0].userName
            let copyItem = JSON.parse(JSON.stringify(item))
            if (copyItem.applyTranslation) {
              copyItem.applyTranslation = 1
            } else {
              copyItem.applyTranslation = 0
            }
            if (copyItem.applyReview) {
              copyItem.applyReview = 1
            } else {
              copyItem.applyReview = 0
            }
            if (copyItem.status) {
              copyItem.status = 0
            } else {
              copyItem.status = 1
            }
            putAction('/production/edit_stage', copyItem).then(({ success, data }) => {
              console.log({ success, data })
            })
          }
        })
        let copyRecord = JSON.parse(JSON.stringify(record))
        if (copyRecord.applyTranslation) {
          copyRecord.applyTranslation = 1
        } else {
          copyRecord.applyTranslation = 0
        }
        if (copyRecord.applyReview) {
          copyRecord.applyReview = 1
        } else {
          copyRecord.applyReview = 0
        }
        if (copyRecord.status) {
          copyRecord.status = 0
        } else {
          copyRecord.status = 1
        }
        putAction('/production/edit_stage', copyRecord).then(({ success, data }) => {
          console.log({ success, data })
        })
      } else {
        if (record.user && record.user.userId != value) {
          /* this.getChapterList(record) */
          this.openRecord = JSON.parse(JSON.stringify(record))
          this.openRecord.userId = value
          this.visible = true
        } else if (!record.user) {
          /* this.getChapterList(record) */
          this.openRecord = JSON.parse(JSON.stringify(record))
          this.openRecord.userId = value
          this.visible = true
        }
      }
    },
    async handleTimeChange(value, option, record) {
      let copyRecord = JSON.parse(JSON.stringify(record))
      if (copyRecord.applyTranslation) {
        copyRecord.applyTranslation = 1
      } else {
        copyRecord.applyTranslation = 0
      }
      if (copyRecord.applyReview) {
        copyRecord.applyReview = 1
      } else {
        copyRecord.applyReview = 0
      }
      if (copyRecord.status) {
        copyRecord.status = 0
      } else {
        copyRecord.status = 1
      }
      putAction('/production/edit_stage', copyRecord).then(({ success, data }) => {
        console.log({ success, data })
      })
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      this.isShow = false
      getAction(this.url.list, params).then(res => {
        if (res.success) {
          res.data = res.data || res.data
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.data.records || res.data
          if (res.data.total) {
            this.ipagination.total = res.data.total - 0
          } else {
            this.ipagination.total = 0
          }
          this.dataSource.forEach(item => {
            if (item.applyTranslation) {
              item.applyTranslation = true
            } else {
              item.applyTranslation = false
            }
            if (item.applyReview) {
              item.applyReview = true
            } else {
              item.applyReview = false
            }
            if (item.status == '1') {
              item.status = false
            } else {
              item.status = true
            }
            item.copyStageAdminUsers=item.stageAdminUsers
          })
          if (this.dataSource.every(item => item.applyTranslation)) {
            this.applyTranslation = true
          }
          if (this.dataSource.every(item => item.applyReview)) {
            this.applyReview = true
          }
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          // 字体预览需要往head里插入css样式
          if (this.isInserstCss) {
            batchStyleInsertHeader(this.dataSource)
          }
          this.isShow = true
          this.loading = false
          this.$forceUpdate()
          return
          getAction('platform/get_platform_stage', { id: this.parent.platformId }).then(res => {
            this.dataSource.forEach(data => {
              let arr = []
              let positionId = ''
              let departmentId = ''
              res.data.forEach(item => {
                if (item.stageId == data.stageId) {
                  positionId = item.positionId
                  departmentId = item.departmentId
                }
              }) /* 
              console.log(res.data.filter(item=>item.positionId==positionId),data,positionId,this.adminUsers.filter(item=>item.userName=='沉舟'),departmentId,123) */
              this.adminUsers.forEach(item => {
                item.positions?.forEach(i => {
                  if (i.positionId == positionId && item.departmentIds.some(d => d == departmentId) && departmentId) {
                    arr.push(item)
                  }
                })
              })
              if (this.parent.adminUsers && this.parent.adminUsers.length) {
                /*                 this.parent.adminUsers?.forEach(item => {
                  this.adminUsers.forEach(i => {
                    if (i.userId == item.userId&&i.departmentIds.some(d=>d==departmentId)&&departmentId) {
                      arr.push(i)
                    }
                  })
                }) */
              } else {
                this.platform.adminUsers?.forEach(item => {
                  if (item.departmentIds.some(d => d == departmentId) && departmentId) {
                    arr.push(item)
                  }
                })
              }

              data.stageAdminUsers = arr.reduce((arr, v) => {
                if (!arr.some(i => i.userId == v.userId)) {
                  arr.push(v)
                }
                return arr
              }, [])
              if (!positionId) {
                //平台下没有该阶段则选择所有用户
                data.stageAdminUsers = this.adminUsers
              }
              if (!data.stageAdminUsers) data.stageAdminUsers = []
            })
            this.loading = false
            /*             getAction(`/production/list_chapter?id=${this.$route.params.id}`).then(res => {
              this.chapterList = res.data?.records
              this.$forceUpdate()
            }) */
          })
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
      })
    }
  },
  created() {
    console.log({ 'this.adminUsers': this.adminUsers })
    this.getRuleList()
    /* this.loadUsersByDepartmentIds() */
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 150)
  },
  computed: {
    url: {
      get: function() {
        return {
          list: `/production/get_production_stage?id=${this.parent.id}`,
          deleteBatch: `/production/delete_stage_batch/${this.parent.id}`
        }
      }
    }
  },
  watch: {
    /*     'parent.platformId': {
      immediate: true,
      handler() {
        this.loadUsersByDepartmentIds()
      }
    } */
  }
}
</script>

<style lang="less" scoped>
.ant-select-selection__rendered {
  img {
    display: none;
  }
}
</style>
